import type { ItemType } from '../../../types/enums';
import { MessageViewVariant } from '../../../types/enums';
import standardDefaultProps from './MessageViewStandard/MessageViewStandardDefaultProps';
import inlineDefaultProps from './MessageViewInline/MessageViewInlineDefaultProps';
import cardDefaultProps from './MessageViewCard/MessageViewCardDefaultProps';
import tableRowDefaultProps from './MessageViewTableRow/MessageViewTableRowDefaultProps';
import type { ItemViewTypeAndProps, ItemViewVariant } from '../../entities/types';
import type { MessageViewCommonProps } from './types';

const variantToComponentAndDefaultPropsMap: Record<MessageViewVariant, MessageViewCommonProps> = {
  standard: standardDefaultProps,
  inline: inlineDefaultProps,
  card: cardDefaultProps,
  tableRow: tableRowDefaultProps
};

/**
 * Merge default props for variant with props supplied with the variant, and additional provided props.
 * @param variant
 * @param additionalProps
 */
function mergeVariantProps<PropsType>(
  variant: ItemViewTypeAndProps<ItemType.MESSAGE, ItemViewVariant<ItemType.MESSAGE>> = {
    type: MessageViewVariant.STANDARD
  },
  additionalProps?: PropsType
): PropsType {
  const defaultProps = variantToComponentAndDefaultPropsMap[variant?.type];
  return { ...defaultProps, ...variant?.props, ...additionalProps };
}

export default mergeVariantProps;
