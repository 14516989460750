import React, { useContext } from 'react';
import dynamic from 'next/dynamic';
import { getLog } from '@aurora/shared-utils/log';
import TextVariantContext from '@aurora/shared-client/components/context/TextVariantContext';
import type { MessageViewFragment } from '../../../types/graphql-types';
import type { ItemType } from '../../../types/enums';
import { MessageViewVariant } from '../../../types/enums';
import mergeVariantProps from './MessageViewDefaultProps';
import type { ItemViewFC, ItemViewVariantFC } from '../../entities/types';
import type { MessageViewCommonProps } from './types';

// eslint-disable-next-line import/no-cycle
const MessageViewStandard = dynamic(() => import('./MessageViewStandard/MessageViewStandard'));
const MessageViewInline = dynamic(() => import('./MessageViewInline/MessageViewInline'));
const MessageViewCard = dynamic(() => import('./MessageViewCard/MessageViewCard'));
const MessageViewTableRow = dynamic(() => import('./MessageViewTableRow/MessageViewTableRow'));

const log = getLog(module);

const variantToComponentMap: Record<
  MessageViewVariant,
  ItemViewVariantFC<MessageViewFragment, ItemType.MESSAGE, MessageViewVariant>
> = {
  standard: MessageViewStandard,
  inline: MessageViewInline,
  card: MessageViewCard,
  tableRow: MessageViewTableRow
};

/**
 * The base message view component that renders the message view specified by
 * the <code>variant</code> parameter. This component should be used anywhere a message is rendered.
 *
 * @author Adam Ayres
 */
const MessageView: ItemViewFC<MessageViewFragment, ItemType.MESSAGE, MessageViewVariant> = ({
  entity: message,
  variant = { type: MessageViewVariant.STANDARD },
  className
}) => {
  const MessageViewComponent = variantToComponentMap[variant?.type];
  const textVariantContext = useContext(TextVariantContext);
  if (MessageViewComponent) {
    const defaultProps = mergeVariantProps<MessageViewCommonProps>(variant);
    return (
      <TextVariantContext.Provider value={{ ...textVariantContext, message, node: message.board }}>
        <MessageViewComponent
          entity={message}
          variant={variant}
          className={className}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...{ ...defaultProps, ...variant.props }}
        />
      </TextVariantContext.Provider>
    );
  }
  log.warn('No registered message view for variant type [%s]', variant.type);
  return null;
};

export default MessageView;
