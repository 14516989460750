import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import { TextAlignment } from '@aurora/shared-types/texts/enums';
import { Card } from 'react-bootstrap';
import { MessageTimestamp, ViewSize } from '../../../../types/enums';
import type { MessageViewCardProps } from '../types';

const defaultViewProps: MessageViewCardProps = {
  useAvatar: false,
  useBoardIcon: false,
  useSearchSnippet: false,
  useAuthorLogin: true,
  useAuthorRank: false,
  useNodeLink: true,
  useSubject: true,
  useSubjectLink: true,
  useMessageTimeLink: false,
  useBody: true,
  useMedia: true,
  usePreviewMedia: true,
  useRepliesCount: true,
  useSolvedBadge: true,
  useTags: false,
  useFooterDivider: false,
  useAttachments: true,
  useIntroduction: false,
  clampBodyLines: false,
  useKudosCount: true,
  useShortTitle: false,
  useSubtitle: true,
  subjectAs: Card.Title,
  avatarSize: IconSize.PX_40,
  useCenteredContent: false,
  portraitClampBodyLines: 3,
  landscapeClampBodyLines: 5,
  useTimeToRead: true,
  useViewCount: true,
  timeStampType: MessageTimestamp.POST_TIME,
  useClickableCard: false,
  useUserHoverCard: true,
  useNodeHoverCard: true,
  useVideoPreview: true,
  textAlignment: TextAlignment.LEFT,
  useVoteComponent: false,
  useAttendeeCount: false,
  viewSize: ViewSize.MD,
  useAttendanceIndicator: false,
  useOccasionTime: false,
  useOccasionLocation: false,
  useMessageStatus: false,
  useOccasionType: false,
  useOccasionBadge: false,
  useManualSortNav: false
};

export default defaultViewProps;
