import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import { MessageTimestamp, MessageViewStandardAvatarPosition } from '../../../../types/enums';
import type { MessageViewStandardProps } from '../types';

const defaultViewProps: MessageViewStandardProps = {
  useAvatar: true,
  useBoardIcon: false,
  useKudosButton: true,
  useReplyButton: true,
  useSearchSnippet: false,
  useAuthorLogin: true,
  useAuthorRank: true,
  useNodeLink: true,
  useSubject: true,
  useCoverImage: false,
  useTimeToRead: false,
  useCoverImageTitle: false,
  useNodeTitle: false,
  useSubjectLink: true,
  useBody: true,
  useMedia: false,
  usePreviewMedia: false,
  useReplies: false,
  useRepliesCount: false,
  useSolvedBadge: true,
  useAcceptedSolutionButton: false,
  useTags: false,
  useFooterDivider: false,
  useAttachments: true,
  useIntroduction: false,
  clampBodyLines: false,
  useKudosCount: false,
  useShortTitle: false,
  subjectAs: 'h5',
  avatarPosition: MessageViewStandardAvatarPosition.LEFT,
  avatarSize: IconSize.PX_40,
  messageReplyProps: {
    useCount: false,
    topicRepliesCount: 10,
    nestedRepliesCount: 3
  },
  timeStampType: MessageTimestamp.POST_TIME,
  useAccordionForReply: false,
  newlyPostedMessages: [],
  useUserHoverCard: true,
  useNodeHoverCard: true,
  useBio: true,
  useAttachmentPreview: true,
  useTagsPreview: true,
  useWideCoverImage: true,
  usePreviewSubjectModal: false,
  useVideoPreview: false,
  useMessageStatus: false,
  useVoteComponent: false,
  useCommentButton: true,
  useOccasionFeaturedGuest: false,
  useOccasionAuthor: false,
  showEscalationBanner: false,
  useCustomFields: true,
  showContributorList: true,
  showTimestamp: true,
  showVersion: true,
  useTkbHelpfulnessRating: false,
  useGuideNavigation: false
};

export default defaultViewProps;
